import * as React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import {
    PageStyle,
    ListItemStyles,
    DescriptionStyle,
    BadgeStyle
} from '../components/style'
import '../components/styles.css'
import styled from "styled-components";


const links = [
    {
        text: "modifying consoles",
        url: "/blog/modifying-consoles",
        latest: true,
        description:
            "Some thoughts on modifying gaming machines old and new",
    },
]

const StyledLink = styled(props => <Link {...props} />)`
  color: dimgrey;
  vertical-align: 5%;
  font-family: amiga4ever;
  @media screen and (max-width : 400px) {
    font-size: 5vw;  }
`;

const BlogPage = () => {
    return (
        <PageStyle>
            <Layout pageTitle="blog">
            </Layout>
            <ul>
                {links.reverse().map(link => (
                    <li key={link.url} style={{...ListItemStyles}}>
            <span>
              <StyledLink to={link.url}>{link.text}</StyledLink>
                {link.latest && (
                    <span style={BadgeStyle} aria-label="New Badge">
                  latest
                </span>
                )}
                <p style={DescriptionStyle}>{link.description}</p>
            </span>
                    </li>
                ))}
            </ul>
        </PageStyle>
    )
}
export default BlogPage